import { UserState } from '@store/reducers/userData';
import React, { useState } from 'react';
import storeConnector from '@store/storeConnector';
import { AlertEntity, HistoricalAlert } from '@src/types/Alerts';
import useAlertMutations from '@src/hooks/api/mutations/useAlertMutations';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Actions } from '@src/types/Actions';
import { isActionRole } from '@src/services/auth';

// eslint-disable-next-line max-lines-per-function
function AcknowledgeCell({
  alarmEntity,
  actions,
  username,
  role,
}: {
  alarmEntity: AlertEntity | HistoricalAlert;
  actions: Actions;
  username: string;
  role: UserState['role'];
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [ackedNote, setAckedNote] = useState('');

  const { acknowledgeAlert } = useAlertMutations();

  const alertTopic = (alarmEntity as HistoricalAlert).topic;
  const alertName =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (alarmEntity as AlertEntity).config?.alertName ||
    (alarmEntity as HistoricalAlert).configAlertName;

  const handleAcknowledge = async () => {
    if (!ackedNote) {
      return;
    }

    setModalOpen(false);
    setIsUpdating(true);

    acknowledgeAlert.mutate(
      {
        alertTopics: [alertTopic],
        ackedBy: username,
        ackedNote,
      },
      {
        onError: async (e) => {
          actions.notifyError(`Failed to acknowledge alarm - ${e}`);
        },
        onSettled: async () => {
          setIsUpdating(false);
        },
      },
    );
  };

  if (alarmEntity.isAcked) {
    return (
      <Tooltip title={(alarmEntity as AlertEntity).ackedNote} placement='left'>
        <Typography>
          {(alarmEntity as AlertEntity).ackedBy ?? 'Acknowledged'}
        </Typography>
      </Tooltip>
    );
  }

  if (!isActionRole(role)) {
    return null;
  }

  if (isUpdating) {
    return <CircularProgress size={25} />;
  }
  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        size='small'
        sx={{ textTransform: 'none' }}
      >
        Acknowledge
      </Button>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Acknowledge: {alertName}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='Leave a note...'
            type='text'
            fullWidth
            variant='standard'
            value={ackedNote}
            onChange={(e) => setAckedNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button onClick={() => setModalOpen(false)} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleAcknowledge} variant='contained'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default storeConnector(AcknowledgeCell, {
  user: ['username', 'role'],
});
