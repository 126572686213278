import { WidgetConfigBase } from './Widget';

export interface SourceDevice {
  type: string;
  namePrefix?: string;
  parentType: string;
  count: number;
}
export interface GenerateDevicePayload {
  id?: string;
  siteControllerId: string;
  unitControllerIds?: string[];
  sourceDevices: SourceDevice[];
}

export interface Device {
  id: string;
  name: string;
  type: string;
  controllerId: string;
  deviceId: string;
  isSiteControllerSourceDevice: boolean;
  engineeringId: string;
  notesRefs: string[];
  deleted: boolean;
}

export interface PointConfig {
  category?: string;
  dataType: string;
  pointName: string;
  id?: string;
  decimalPrecision?: number;
  name: string;
  pointAlias?: string;
  _id?: string;
  __v?: number;
}

export type DeviceExtended = Device & {
  isNew?: boolean;
  remove?: boolean;
};

export type PointConfigExtended = PointConfig & {
  isNew?: boolean;
  remove?: boolean;
};

export type CollectionPayload =
  | GenerateDevicePayload
  | DeviceExtended
  | PointConfigExtended
  | Device
  | PointConfig
  | WidgetConfigBase;

export enum CollectionTypes {
  DEVICES,
  POINT_CONFIGS,
  WIDGETS,
}

export enum PCActionType {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
  BULK_DELETE = 'bulk_delete',
}
