const pointConfigSx = {
  collectionGrid: {
    '& .MuiDataGrid-overlayWrapperInner': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  confirm: {
    top: '2em',
    alert: {
      color: 'white',
    },
  },
  pcDialogPaper: {
    width: '100%',
    height: '100%',
    maxWidth: 800,
    display: 'flex',
    maxHeight: 600,
    '& .jsoneditor-preview': {
      color: 'white',
    },
  },
};

export default pointConfigSx;
