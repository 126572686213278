import { UUID } from 'crypto';

export function isAlertEntityConfig(o: unknown): o is AlertConfig {
  return (
    o != null &&
    typeof o === 'object' &&
    'id' in o &&
    'alertName' in o &&
    'topic' in o &&
    'severity' in o
  );
}

export enum SeverityTypes {
  'Warning' = 0,
  'Alarm' = 1,
  'Fire' = 2,
}

export interface AlertConfig {
  id: UUID;
  alertName: string;
  topic: string;
  thresholdMin: number | null;
  thresholdMax: number | null;
  messageAboveMax: string | null;
  messageBelowMin: string | null;
  severity: SeverityTypes;
  updatedAt: string;
  timeoutActivateMs?: number | null;
  timeoutRemoveMs?: number | null;
  isEquipmentFault?: boolean;
}

export type AlertConfigExtended = AlertConfig & {
  isNew?: boolean;
  id: UUID | null;
  remove?: boolean;
};

export interface AlertEntity {
  ts: string;
  message: string;
  config: AlertConfig;
  value: number;
  topic: string;
  isAcked: boolean;
  ackedBy?: string;
  ackedTs?: string;
  ackedNote?: string;
  isManualReset?: string;
}

export interface AlertsState {
  activeAlerts: AlertEntity[];
}

export interface TimelineChartSeries {
  name: string;
  data: {
    x: 'Alert active';
    y: [number, number] | [];
    [key: string]: unknown;
  }[];
  color: string;
}

export interface HistoricalAlert {
  configSeverity: AlertConfig['severity'];
  configAlertName: AlertConfig['alertName'];
  configId: AlertConfig['id'];
  isEquipmentFault: string;
  isDraftDelete: boolean;
  isDraft: boolean;
  isAcked: boolean;
  ts: string;
  isActive: boolean;
  message: string;
  topic: string;
  isManualReset: string;
}

export interface AckAlertDto {
  alertTopics: string[];
  ackedBy: string;
  ackedNote: string;
}

export interface ClearAlertDto {
  topic: string;
}

export enum AlertActionType {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
  BULK_DELETE = 'bulk_delete',
}

export enum AlertType {
  ACTIVE_ALERT = 'activeAlert',
  HISTORIAN_ALERT = 'historicAlert',
}

export enum AlertColumn {
  TIME_EVENT = 'timeEvent',
  SEVERITY = 'severity',
  EVENT_TYPE = 'eventType',
  UNIT = 'unit',
  ALERT_NAME = 'alertName',
  MESSAGE = 'message',
  TOPIC = 'topic',
  HELP = 'help',
  ACKNOWLEDGE = 'acknowledge',
  CLEAR = 'clear',
  IS_EQUIPMENT_FAULT = 'isEquipmentFault',
}
