import React, { ReactNode, useMemo } from 'react';
import CautionIcon from '@assets/caution.svg?react';
import WarningIcon from '@assets/warning.svg?react';
import FireIcon from '@assets/fire.svg?react';
import storeConnector from '@store/storeConnector';
import { AlertsState, SeverityTypes } from '@src/types/Alerts';
import { Link } from 'react-router-dom';

interface AlertIconsProps {
  activeAlerts: AlertsState['activeAlerts'];
}

function AlertIcons({ activeAlerts }: AlertIconsProps): ReactNode {
  const alertsCount = useMemo(() => {
    const counts: Record<SeverityTypes, number> = {
      [SeverityTypes.Warning]: 0,
      [SeverityTypes.Alarm]: 0,
      [SeverityTypes.Fire]: 0,
    };

    activeAlerts.forEach((alert) => {
      if (!alert.isAcked) {
        counts[alert.config.severity] += 1;
      }
    });

    return counts;
  }, [activeAlerts]);

  const activeAlertsPagePath = `/home/alerts/active`;

  return (
    <div className='alarm-icons'>
      {alertsCount[SeverityTypes.Warning] > 0 && (
        <Link
          to={activeAlertsPagePath}
          state={{ severity: SeverityTypes.Warning }}
          className='link'
        >
          <div>
            <CautionIcon className='icon active' />
            <div>{alertsCount[SeverityTypes.Warning]}</div>
          </div>
        </Link>
      )}
      {alertsCount[SeverityTypes.Alarm] > 0 && (
        <Link
          to={activeAlertsPagePath}
          state={{ severity: SeverityTypes.Alarm }}
          className='link'
        >
          <div>
            <WarningIcon className='icon active' />
            <div>{alertsCount[SeverityTypes.Alarm]}</div>
          </div>
        </Link>
      )}
      {alertsCount[SeverityTypes.Fire] > 0 && (
        <Link
          to={activeAlertsPagePath}
          state={{ severity: SeverityTypes.Fire }}
          className='link'
        >
          <div>
            <FireIcon className='icon active' />
            <div>{alertsCount[SeverityTypes.Fire]}</div>
          </div>
        </Link>
      )}
    </div>
  );
}

export default storeConnector(AlertIcons, {
  config: ['siteMeta'],
  alerts: ['activeAlerts'],
});
