import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Alert,
} from '@mui/material';
import useAlertConfigMutations from '@src/hooks/api/mutations/useAlertConfigsMutations';
import QueryKeys from '@src/constants/queryKeys';
import { useQueryClient } from '@tanstack/react-query';

interface BulkUploadProps {
  importFile: File;
  handleCloseBulkDialog: () => void;
}
interface ApiError {
  error: {
    message: string;
  };
}

export default function AlertsConfigBulkDialog({
  importFile,
  handleCloseBulkDialog,
}: BulkUploadProps) {
  const { createBulkAlertConfigs } = useAlertConfigMutations();
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const handleUpload = () =>
    createBulkAlertConfigs.mutate(importFile, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.alerts],
        });
        setError(null);
        return handleCloseBulkDialog();
      },
      onError: (e: Error) => {
        const cause = e.cause as ApiError;

        setError(cause.error.message);
      },
    });

  const handleCancel = () => {
    setError(null);
    handleCloseBulkDialog();
  };

  return (
    <Dialog open>
      {error && (
        <Alert
          variant='outlined'
          severity='error'
          sx={{
            m: 1,
          }}
        >
          {error}
        </Alert>
      )}
      <DialogTitle id='alert-dialog-title'>Upload bulk configs</DialogTitle>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleUpload} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
