import useApiMutation from '@src/hooks/useApiMutation';

export default (id?: string) => {
  const alertsBasePath = '/alerts/alert-configs';

  const alertPath = `${alertsBasePath}${id ? `/${id}` : ''}`;

  const createAlertConfig = useApiMutation({
    apiPath: alertsBasePath,
    method: 'POST',
  });

  const createBulkAlertConfigs = useApiMutation({
    apiPath: `${alertsBasePath}/import-file`,
    method: 'POST',
    isFileUpload: true,
    headers: {},
  });

  const updateAlertConfig = useApiMutation({
    apiPath: alertPath,
    method: 'PATCH',
  });

  const deleteAlertConfig = useApiMutation({
    apiPath: alertPath,
    method: 'DELETE',
  });
  const bulkDeleteAlertConfigs = useApiMutation({
    apiPath: `${alertsBasePath}/bulk-delete`,
    method: 'POST',
  });

  return {
    createAlertConfig,
    createBulkAlertConfigs,
    updateAlertConfig,
    deleteAlertConfig,
    bulkDeleteAlertConfigs,
  };
};
