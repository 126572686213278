import useApiMutation from '@src/hooks/useApiMutation';

export default (pointAlias?: string) => {
  const pcPath = `/point-configs${pointAlias ? `/${pointAlias}` : ''}`;
  const createPointConfig = useApiMutation({
    apiPath: pcPath,
    method: 'POST',
    useV2API: true,
  });

  const updatePointConfig = useApiMutation({
    apiPath: pcPath,
    method: 'PATCH',
    useV2API: true,
  });

  const deletePointConfig = useApiMutation({
    apiPath: pcPath,
    method: 'DELETE',
    useV2API: true,
  });

  return { createPointConfig, updatePointConfig, deletePointConfig };
};
