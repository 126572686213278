const prepColSx = {
  alertIsEquipmentFaultBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
};

export default prepColSx;
