export default [
  {
    field: 'name',
    headerName: 'Name',
    description: 'Name displayed in the UI',
    type: 'string',
    width: 200,
  },
  {
    field: 'pointName',
    headerName: 'Point Name',
    description: 'Name of the point',
    type: 'string',
    width: 200,
  },
  {
    field: 'category',
    headerName: 'Category',
    description: 'Category of the point',
    type: 'string',
    width: 200,
  },
  {
    field: 'dataType',
    headerName: 'Data Type',
    description: 'Type of data the point holds',
    type: 'string',
    width: 200,
  },

  {
    field: 'decimalPrecision',
    headerName: 'Decimal Precision',
    description: 'Number of decimal places',
    type: 'number',
    width: 200,
  },
];
