import React from 'react';
import type { ReactElement } from 'react';
import PCSContainerEPC from '@components/_shared/PCSContainers/PCSContainerEPC';
import PCSContainerEPCmulti from '@components/_shared/PCSContainers/PCSContainerEPCmulti';
import PCSContainerPE from '@components/_shared/PCSContainers/PCSContainerPE';
import PCSContainerPEDC from '@components/_shared/PCSContainers/PCSContainerPEDC';
import PCSContainerSungrow from '@components/_shared/PCSContainers/PCSContainerSungrow';
import PCSContainerDefault from '@components/_shared/PCSContainers/PCSContainerDefault';
import PCSContainerSungrowBESS from './PCSContainerSungrowBESS';
import PCSContainerPE4bus from './PCSContainerPE4bus';

interface SwitchContainerProps {
  pcsIndex: number;
  pcsType: string;
  fromPV?: boolean;
}

function SwitchPCSContainer(p: SwitchContainerProps): ReactElement {
  switch (p.pcsType) {
    case 'EPC':
      return <PCSContainerEPC {...p} />;
    case 'EPCmulti':
      return <PCSContainerEPCmulti {...p} />;
    case 'PE':
      return <PCSContainerPE {...p} />;
    case 'PE4bus':
      return <PCSContainerPE4bus {...p} />;
    case 'PEDC':
      return <PCSContainerPEDC {...p} />;
    case 'Sungrow':
      return <PCSContainerSungrow {...p} />;
    case 'SungrowBESS':
      return <PCSContainerSungrowBESS {...p} />;
    default:
      return <PCSContainerDefault {...p} />;
  }
}

export default SwitchPCSContainer;
