import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EditUserFormValues } from '@hooks/api/mutations/useUserUpdate';

interface MfaRequiredFieldProps {
  totp?: boolean;
}

function MfaRequiredField({ totp }: MfaRequiredFieldProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setValue } = useFormContext<EditUserFormValues>();

  return (
    <FormControlLabel
      control={
        <Checkbox
          defaultChecked={totp}
          onChange={(e) => setValue('totp', e.target.checked)}
          size='small'
        />
      }
      label='MFA Required'
    />
  );
}

export default MfaRequiredField;
