import useApiQuery from '@hooks/useApiQuery';
import QueryKeys from '@src/constants/queryKeys';
import { PointConfig } from '@src/types/Collection';

export default () =>
  useApiQuery<PointConfig[]>({
    queryKey: [QueryKeys.pointConfigs],
    apiPath: '/point-configs',
    method: 'GET',
    useV2API: true,
  });
