import useApiMutation from '@src/hooks/useApiMutation';

export default () => {
  const alertsBasePath = '/alerts/alerts';

  const acknowledgeAlert = useApiMutation({
    apiPath: `${alertsBasePath}/ack`,
    method: 'POST',
  });

  const clearAlert = useApiMutation({
    apiPath: `${alertsBasePath}/clear`,
    method: 'DELETE',
  });

  return {
    acknowledgeAlert,
    clearAlert,
  };
};
