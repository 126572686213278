import { UserState } from '@store/reducers/userData';
import React, { useState } from 'react';
import storeConnector from '@store/storeConnector';
import { AlertEntity, HistoricalAlert } from '@src/types/Alerts';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Box,
} from '@mui/material';
import { Actions } from '@src/types/Actions';
import { isSuperAdmin } from '@src/services/auth';
import useAlertMutations from '@hooks/api/mutations/useAlertMutations';
import alertsx from '@components/_shared/Alerts/AlertsSx';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// eslint-disable-next-line max-lines-per-function
function ClearCell({
  alertEntity,
  actions,
  role,
}: {
  alertEntity: AlertEntity | HistoricalAlert;
  actions: Actions;
  role: UserState['role'];
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const alertTopic = (alertEntity as HistoricalAlert).topic;
  const alertName =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (alertEntity as AlertEntity).config?.alertName ||
    (alertEntity as HistoricalAlert).configAlertName;

  const { clearAlert } = useAlertMutations();

  const handleClear = async () => {
    setModalOpen(false);
    setIsUpdating(true);

    clearAlert.mutate(
      {
        topic: alertTopic,
      },
      {
        onError: async (e) => {
          actions.notifyError(`Failed to clear alert - ${e}`);
        },
        onSettled: async () => {
          setIsUpdating(false);
        },
      },
    );
  };

  if (!isSuperAdmin(role)) {
    return null;
  }

  if (isUpdating) {
    return <CircularProgress size={25} />;
  }

  const entityName = (alertEntity as AlertEntity).config?.alertName
    ? 'alertEntity'
    : 'historicAlert';

  const renderElement = {
    alertEntity: (
      <Button
        onClick={() => setModalOpen(true)}
        size='small'
        sx={alertsx.alertButton}
      >
        Clear
      </Button>
    ),
    historicAlert: (
      <Box sx={alertsx.historicAlertBox}>
        <Typography variant='body2' sx={alertsx.historicAlertText}>
          {alertEntity?.isManualReset === 'true' ? (
            <FontAwesomeIcon icon={faCheck} size='lg' />
          ) : null}
        </Typography>
      </Box>
    ),
  };
  return (
    <>
      {renderElement[entityName]}
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Clear: {alertName}</DialogTitle>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button onClick={() => setModalOpen(false)} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleClear} variant='contained'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default storeConnector(ClearCell, {
  user: ['role'],
});
